import * as React from "react";
import Layout from "../components/layout";
import { HelmetDatoCms } from "gatsby-source-datocms";

import HomepageHero from "../sections-static/homepage-hero";
// import HomepageThreeD from "../sections-static/homepage-three-d";
import LogoFalling from "../sections-static/logo-falling";
import HomepageCallouts from "../sections-static/homepage-callouts";

import { useI18next } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import PageBuilder from "../components/pagebuilder";

const IndexPage = ({ data, location }) => {
  const { language } = useI18next();

  return (
    <Layout key="index" location={location}>
      <HelmetDatoCms seo={data.homepage.seoMetaTags} />
      <main key="main">
        <HomepageHero
          lang={language}
          data={data.homepage}
          location={location}
        />
        <PageBuilder data={data.homepage.topBuilder} lang={language} />
        {/* <HomepageFeatures lang={language} data={data.homepage} /> */}
        {/* <HomepageThreeD lang={language} data={data.homepage} /> */}
        {/* <HomepageValues lang={language} data={data.homepage} /> */}
        {/* <HomepageCreators lang={language} data={data.homepage} /> */}
        <PageBuilder data={data.homepage.bottomBuilder} lang={language} />
        <LogoFalling lang={language} data={data.homepage} />
        <HomepageCallouts lang={language} data={data.homepage} />
        {/* <YoutubeEmbed video={data.homepage.videoOverlay} /> */}
      </main>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query IndexPage($language: String!) {
    homepage: datoCmsHomepage(locale: { eq: $language }) {
      originalId
      topBuilder {
        __typename
        ... on DatoCmsImageContent {
          ...ImageContent
        }
        ... on DatoCmsColumnedList {
          ...ColumnedList
        }
        ... on DatoCmsCarousel {
          ...Carousel
        }
        ... on DatoCmsFeatureCarousel {
          ...Features
        }
        ... on DatoCmsNicheSelector {
          ...Niches
        }
        ... on DatoCmsCustomerTestimonial {
          ...Testimonials
        }
        ... on DatoCmsValuesList {
          ...Values
        }
        ... on DatoCmsCallToActionBox {
          ...CTABox
        }
        ... on DatoCmsImageVideo {
          ...ImageVideo
        }
      }
      bottomBuilder {
        __typename
        ... on DatoCmsImageContent {
          ...ImageContent
        }
        ... on DatoCmsColumnedList {
          ...ColumnedList
        }
        ... on DatoCmsCarousel {
          ...Carousel
        }
        ... on DatoCmsFeatureCarousel {
          ...Features
        }
        ... on DatoCmsNicheSelector {
          ...Niches
        }
        ... on DatoCmsCustomerTestimonial {
          ...Testimonials
        }
        ... on DatoCmsValuesList {
          ...Values
        }
        ... on DatoCmsCallToActionBox {
          ...CTABox
        }
        ... on DatoCmsImageVideo {
          ...ImageVideo
        }
      }

      heroBody
      heroBtnText
      heroBtnUrl
      heroHeadline
      heroInputPlaceholder
      heroInputSite
      heroImage {
        url(imgixParams: { auto: "enhance", q: 100 })
        alt
        width
        height
      }
      sparkle {
        alt
        url(imgixParams: { auto: "enhance", q: 100, w: "360" })
        width
        height
      }

      threeDHeadline
      phone {
        url(imgixParams: { auto: "enhance", q: 100, w: "380" })
        width
        height
      }
      twitter {
        url(imgixParams: { auto: "enhance", q: 100, w: "360" })
        width
        height
      }
      spotify {
        url(imgixParams: { auto: "enhance", q: 100, w: "360" })
        width
        height
      }
      amazon {
        url(imgixParams: { auto: "enhance", q: 100, w: "360" })
        width
        height
      }
      youtube {
        url(imgixParams: { auto: "enhance", q: 100, w: "360" })
        width
        height
      }
      facebook {
        url(imgixParams: { auto: "enhance", q: 100, w: "360" })
        width
        height
      }
      instagram {
        url(imgixParams: { auto: "enhance", q: 100, w: "360" })
        width
        height
      }
      linkedin {
        url(imgixParams: { auto: "enhance", q: 100, w: "360" })
        width
        height
      }
      pinterest {
        url(imgixParams: { auto: "enhance", q: 100, w: "360" })
        width
        height
      }
      snapchat {
        url(imgixParams: { auto: "enhance", q: 100, w: "360" })
        width
        height
      }
      tiktok {
        url(imgixParams: { auto: "enhance", q: 100, w: "360" })
        width
        height
      }

      callouts {
        id
        btnText
        btnUrl
        mainTitle
        subtitle
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }

    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
