import * as React from "react";
import DynamicLink from "../components/link";

const HomepageCallouts = ({ language, data }) => {
  return (
    <section className="homepage-callouts infront">
      {data.callouts.map((callout) => (
        <div key={callout.id} className="homepage-callouts-item">
          <h6>{callout.subtitle}</h6>
          <h5 className="bold">{callout.mainTitle}</h5>
          <DynamicLink
            href={callout.btnUrl}
            language={language}
            classes="btn btn--purple"
            text={callout.btnText}
            type="button"
          />
        </div>
      ))}
    </section>
  );
};

export default HomepageCallouts;
