import React, { useEffect, useMemo } from "react";

import GlobalData from "../data/global-images";
import DynamicLink from "../components/link";
const HomepageHero = ({ language, data, location }) => {
  const images = GlobalData();
  // Weird fix for sparkle placement on Safari
  // Absolute positioning was broken before the image had loaded so adding it back after load to position correctly
  useEffect(() => {
    console.log("Document loaded");
    const sparkles = document.querySelectorAll(".sparkle");
    for (const sparkle of sparkles) {
      sparkle.classList.add("absolute");
    }
  });

  return (
    <section className="homepage-hero" style={{ minHeight: "100vh" }}>
      <div className="content">
        <h1 className="prewrap">{data.heroHeadline}</h1>
        <p className="lede">{data.heroBody}</p>
        <div className="signup">
          <div className="username-container">
            <span className="input-heading">{data.heroInputSite}/</span>
            <input
              className="username-input js-username-input"
              type="text"
              maxLength="20"
              id="username"
              placeholder={data.heroInputPlaceholder}
              name="username"
            />
          </div>
          <DynamicLink
            href={`${data.heroBtnUrl}`}
            classes="btn btn--purple btn--arrow js-sign-up"
            type={"button"}
            text={data.heroBtnText}
          />
        </div>
      </div>
      <div
        className="hero-image-grid-container"
        style={{ minHeight: "480px", minWidth: "100%" }}
      >
        <div className="hero-image-inner">
          <img
            className="hero-image"
            id="hero-image"
            sizes="1600px, 100vw"
            width="648"
            height="832"
            decoding="sync"
            loading="eager"
            alt={data.heroImage.alt}
            src={`${data.heroImage.url}&w=1600`}
            srcSet={`
                            ${data.heroImage.url}&w=800 300w,
                            ${data.heroImage.url}&dpr=0.5&w=1200 600w,
                            ${data.heroImage.url}&w=1600 1200w
                            `}
          />
          {/* <div className="sticker-container">
            <div
              className="sticker sticker--green"
              style={{
                backgroundImage: `url('${images.global.stickerCallout.url}')`,
              }}
            >
              {data.heroSticker}
            </div>
          </div> */}
          <div className="sparkle-container">
            <img
              className="sparkle sparkle-large"
              id="sparkle-1"
              src={data.sparkle.url}
              alt={data.sparkle.alt}
              width={data.sparkle.width}
              height={data.sparkle.height}
              loading="lazy"
            />
            <img
              className="sparkle sparkle-large"
              id="sparkle-2"
              src={data.sparkle.url}
              alt={data.sparkle.alt}
              width={data.sparkle.width}
              height={data.sparkle.height}
              loading="lazy"
            />
            <img
              className="sparkle sparkle-large"
              id="sparkle-3"
              src={data.sparkle.url}
              alt={data.sparkle.alt}
              width={data.sparkle.width}
              height={data.sparkle.height}
              loading="lazy"
            />
            <img
              className="sparkle sparkle-large"
              id="sparkle-4"
              src={data.sparkle.url}
              alt={data.sparkle.alt}
              width={data.sparkle.width}
              height={data.sparkle.height}
              loading="lazy"
            />
            <img
              className="sparkle sparkle-medium"
              id="sparkle-5"
              src={data.sparkle.url}
              alt={data.sparkle.alt}
              width={data.sparkle.width}
              height={data.sparkle.height}
              loading="lazy"
            />
            <img
              className="sparkle sparkle-medium"
              id="sparkle-6"
              src={data.sparkle.url}
              alt={data.sparkle.alt}
              width={data.sparkle.width}
              height={data.sparkle.height}
              loading="lazy"
            />
            <img
              className="sparkle sparkle-medium"
              id="sparkle-7"
              src={data.sparkle.url}
              alt={data.sparkle.alt}
              width={data.sparkle.width}
              height={data.sparkle.height}
              loading="lazy"
            />
            <img
              className="sparkle sparkle-small"
              id="sparkle-8"
              src={data.sparkle.url}
              alt={data.sparkle.alt}
              width={data.sparkle.width}
              height={data.sparkle.height}
              loading="lazy"
            />
            <img
              className="sparkle sparkle-small"
              id="sparkle-9"
              src={data.sparkle.url}
              alt={data.sparkle.alt}
              width={data.sparkle.width}
              height={data.sparkle.height}
              loading="lazy"
            />
            <img
              className="sparkle sparkle-small"
              id="sparkle-10"
              src={data.sparkle.url}
              alt={data.sparkle.alt}
              width={data.sparkle.width}
              height={data.sparkle.height}
              loading="lazy"
            />
          </div>
        </div>
      </div>
      <div className="cloud-background-container">
        <div className="cloud-background">
          <img
            className="cloud-animate"
            src={images.global.horizontalCloud.url}
            alt={images.global.horizontalCloud.alt}
            height={images.global.horizontalCloud.height}
            width={images.global.horizontalCloud.width}
            loading="lazy"
          />
          <img
            className="cloud-animate"
            src={images.global.horizontalCloud.url}
            alt={images.global.horizontalCloud.alt}
            height={images.global.horizontalCloud.height}
            width={images.global.horizontalCloud.width}
            loading="lazy"
          />
        </div>
      </div>
    </section>
  );
};

export default HomepageHero;
